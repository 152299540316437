import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import {
  Hero,
  Lifestyle,
  SafeToSpend,
  Steps,
  WayYouPay,
} from 'page-components/income';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface IncomeProps {}

const Income: FC<IncomeProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper background="linear-gradient(180.07deg, #EBF8F2 0.4%, rgba(186, 225, 209, 0.26) 70.11%, rgba(255, 207, 173, 0.08) 77.86%, #EBF8F2 92.81%)">
      <Hero />
      <Lifestyle />
      <SafeToSpend />
      <WayYouPay />
      <Steps />
      <BottomCta
        heading="Spend retirement with greater peace of mind."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default Income;

export const Head = () => (
  <SEO
    title="Retirement Income Designed to Last | Retirable"
    description="Retirable's decumulation experts provide a monthly retirement income so you can comfortably spend down your nest egg with confidence."
    imagePath="income-featured.png"
  />
);
