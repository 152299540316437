import { Circle, Icon } from 'component-library/components/icons';
import { IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { useState } from 'react';

import { SERVICES } from '../shared';

export const Services = () => {
  const [showServices, setShowServices] = useState(false);

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setShowServices((prev) => !prev)}
      >
        <Body variant={2} medium marginBottom={showServices ? 24 : undefined}>
          Our Platform
        </Body>
        <Circle small>
          <Icon
            name={showServices ? IconName.ChevronUp : IconName.ChevronDown}
            color={colors.white}
          />
        </Circle>
      </Flex>
      {showServices &&
        SERVICES.map(({ label, to }, idx) => (
          <LinkWrapper to={to} key={idx}>
            <Body
              medium
              variant={3}
              marginBottom={idx === SERVICES.length - 1 ? 0 : 20}
            >
              {label}
            </Body>
          </LinkWrapper>
        ))}
    </>
  );
};
