import { Flex } from 'component-library/components/layout/Flex';
import { device } from 'component-library/styles/device';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Services } from './Services';
import { MENU_ITEMS } from '../shared';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { retirablePhoneNumber } from 'helpers/businessConstants';
import { LogInButton } from 'component-library/components/buttons/LogInButton';
import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';

const DropdownMenuWrapper = styled(Flex)`
  padding: 32px 24px;
  background-color: #dbf0e7;

  ${`@media ${device.sm}`} {
    position: fixed;
    top: 65px;
    bottom: 0;
    width: 100%;
    overflow-x: scroll;
  }
`;

const MenuItem = styled.div`
  border-bottom: 1px solid rgba(38, 49, 59, 0.16);
  padding: 16px 0 20px;
`;

interface DropdownMenuProps {
  phoneNumber?: string;
  onPrimaryCtaClick?: () => void;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ phoneNumber, onPrimaryCtaClick }) => {
  return (
    <DropdownMenuWrapper column>
      <MenuItem>
        <Services />
      </MenuItem>
      {MENU_ITEMS.map(({ label, to }, idx) => (
        <MenuItem key={idx}>
          <LinkWrapper to={to}>
            <Body variant={2} medium>
              {label}
            </Body>
          </LinkWrapper>
        </MenuItem>
      ))}
      <LinkWrapper to={`tel:${phoneNumber || retirablePhoneNumber}`}>
        <Body marginTop={16} marginBottom={64} variant={2} medium>
          Call Us
        </Body>
      </LinkWrapper>
      <LogInButton marginBottom={24} />
      <GetStartedButton onClick={onPrimaryCtaClick} />
    </DropdownMenuWrapper>
  );
};