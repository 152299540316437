import { Flex } from 'component-library/components/layout/Flex';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface SectionLargeProps {
  backgroundColor: string;
  image: React.ReactNode;
  imageAlignment: 'left' | 'right';
}

const SectionWrapperLarge = styled(Flex)<
  Pick<SectionLargeProps, 'backgroundColor' | 'imageAlignment'>
>`
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 32px;
  margin: 40px;
  padding: 40px;
  gap: 40px;
  flex-direction: ${({ imageAlignment }) =>
    imageAlignment === 'right' ? 'row' : 'row-reverse'};

  > ${Flex} {
    flex-basis: 50%;
  }
`;

const Wrapper = styled.div<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
`;

export const SectionLarge: FC<PropsWithChildren<SectionLargeProps>> = ({
  backgroundColor,
  image,
  imageAlignment,
  children,
  ...rest
}) => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <SectionWrapperLarge
      backgroundColor={backgroundColor}
      imageAlignment={imageAlignment}
      {...rest}
    >
      <Flex
        justifyContent={imageAlignment === 'right' ? 'flex-end' : 'flex-start'}
      >
        <Wrapper maxWidth={635}>{children}</Wrapper>
      </Flex>
      <Flex
        justifyContent={imageAlignment === 'right' ? 'flex-start' : 'flex-end'}
      >
        <Wrapper maxWidth={724}>{image}</Wrapper>
      </Flex>
    </SectionWrapperLarge>
  </ShowOn>
);
