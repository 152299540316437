import { Flex } from 'component-library/components/layout/Flex';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { device } from 'component-library/styles/device';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface SectionSmallProps {
  backgroundColor: string;
  image: React.ReactNode;
  withImagePadding?: boolean;
  imageAlignment?: 'top' | 'bottom';
}

const SectionWrapperSmall = styled(ShowOn).attrs({
  screens: [Screen.Sm, Screen.Md],
})<Pick<SectionSmallProps, 'backgroundColor'>>`
  background: ${({ backgroundColor }) => backgroundColor};
`;

const Text = styled.div<Pick<SectionSmallProps, 'imageAlignment'>>`
  padding: ${({ imageAlignment }) =>
    imageAlignment === 'top' ? '40px 20px 80px' : '40px 20px'};
`;

const Image = styled(Flex)<
  Pick<SectionSmallProps, 'withImagePadding' | 'imageAlignment'>
>`
  padding: ${({ withImagePadding }) => (withImagePadding ? '0 20px' : '0')};
  padding-top: ${({ imageAlignment }) =>
    imageAlignment === 'top' ? '40px' : '0'};
  padding-bottom: ${({ imageAlignment }) =>
    imageAlignment === 'top' ? '0' : '56px'};
  justify-content: center;

  ${`@media ${device.md}`} {
    width: 400px;
    margin: 0 auto;
  }
`;

export const SectionSmall: FC<PropsWithChildren<SectionSmallProps>> = ({
  backgroundColor,
  image,
  withImagePadding = false,
  children,
  imageAlignment = 'top',
}) => {
  const imageComponent = (
    <Image withImagePadding={withImagePadding} imageAlignment={imageAlignment}>
      {image}
    </Image>
  );

  return (
    <SectionWrapperSmall backgroundColor={backgroundColor}>
      {imageAlignment === 'top' && imageComponent}
      <Text imageAlignment={imageAlignment}>{children}</Text>
      {imageAlignment === 'bottom' && imageComponent}
    </SectionWrapperSmall>
  );
};
