import { Location, WindowLocation } from '@reach/router';
import { isExternalLink } from 'component-library/components/links/linkUtils';
import { graphql, Script, ScriptStrategy, StaticQuery } from 'gatsby';
import { SeoFragment } from 'graphqlTypes';
import schemaGenerator, { ArticleDetails } from 'helpers/schemaGenerator';
import { titleize } from 'helpers/titleize';
import React from 'react';

interface SEOProps {
  title: string;
  description: string;
  imagePath?: string | null;
  article?: ArticleDetails | null;
}

interface InternalSEOProps extends SEOProps, SeoFragment {
  location: WindowLocation;
}

interface getImageUrlParams {
  siteUrl?: string | null;
  imagePath?: string | null;
}

const getImageUrl = ({ siteUrl, imagePath }: getImageUrlParams) => {
  const internalImagePath = `${siteUrl}/featured/${imagePath ||
    'homepage-featured.png'}`;

  if (!imagePath) {
    return internalImagePath;
  }

  return isExternalLink(imagePath) ? imagePath : internalImagePath;
};

const Head = ({
  // location
  location,
  // global site properties
  siteTitle,
  siteTitleShort,
  siteUrl,
  themeColor,
  social,
  // specific to page
  title: pageTitle,
  description,
  imagePath,
  // specific to article
  article,
}: InternalSEOProps) => {
  const canonical = siteUrl + location.pathname;
  const imageUrl = getImageUrl({ imagePath, siteUrl });
  const title = titleize(pageTitle);

  return (
    <>
      <html lang="en" />

      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />
      <Script
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy={ScriptStrategy.postHydrate}
      />
      <Script
        src="//front.optimonk.com/public/184425/js/preload.js"
        strategy={ScriptStrategy.idle}
      />

      <meta content={siteTitle || ''} name="apple-mobile-web-app-title" />
      <meta content={title} property="og:title" />
      <meta content={title} name="twitter:title" />
      <title>{title}</title>

      <meta content={description} name="description" />
      <meta content={description} property="og:description" />
      <meta content={description} name="twitter:description" />

      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={themeColor || ''} name="theme-color" />
      <meta content={siteTitleShort || ''} name="application-name" />

      <meta content={article ? 'article' : 'website'} property="og:type" />
      <meta content={siteTitleShort || ''} property="og:site_name" />
      <meta content={social?.fbAppId || ''} property="fb:app_id" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={`@${social?.twitter}`} name="twitter:site" />
      <meta content={`@${social?.twitter}`} name="twitter:creator" />
      <meta content={title} name="twitter:text:title" />
      <meta content={canonical} property="og:url" />
      <meta content={canonical} name="twitter:url" />
      <link rel="canonical" href={canonical} />

      <meta content={article?.imageUrl || imageUrl} property="og:image" />
      <meta content="1024" property="og:image:width" />
      <meta content="512" property="og:image:height" />

      <meta content={article?.imageUrl || imageUrl} name="twitter:image" />
      <meta content="1024" name="twitter:image:width" />
      <meta content="512" name="twitter:image:height" />

      <meta content={themeColor || ''} name="msapplication-TileColor" />
      <meta
        content="/icons/mstile-70x70.png"
        name="msapplication-square70x70"
      />
      <meta
        content="/icons/mstile-144x144.png"
        name="msapplication-square144x144"
      />
      <meta
        content="/icons/mstile-150x150.png"
        name="msapplication-square150x150"
      />
      <meta
        content="/icons/mstile-310x150.png"
        name="msapplication-wide310x150"
      />
      <meta
        content="/icons/mstile-310x310.png"
        name="msapplication-square310x310"
      />

      <link href="/manifest.json" rel="manifest" />

      <link
        href="/icons/apple-touch-icon-57x57.png"
        rel="apple-touch-icon"
        sizes="57x57"
      />
      <link
        href="/icons/apple-touch-icon-60x60.png"
        rel="apple-touch-icon"
        sizes="60x60"
      />
      <link
        href="/icons/apple-touch-icon-72x72.png"
        rel="apple-touch-icon"
        sizes="72x72"
      />
      <link
        href="/icons/apple-touch-icon-76x76.png"
        rel="apple-touch-icon"
        sizes="76x76"
      />
      <link
        href="/icons/apple-touch-icon-114x114.png"
        rel="apple-touch-icon"
        sizes="114x114"
      />
      <link
        href="/icons/apple-touch-icon-120x120.png"
        rel="apple-touch-icon"
        sizes="120x120"
      />
      <link
        href="/icons/apple-touch-icon-144x144.png"
        rel="apple-touch-icon"
        sizes="144x144"
      />
      <link
        href="/icons/apple-touch-icon-152x152.png"
        rel="apple-touch-icon"
        sizes="152x152"
      />
      <link
        href="/icons/apple-touch-icon-167x167.png"
        rel="apple-touch-icon"
        sizes="167x167"
      />
      <link
        href="/icons/apple-touch-icon-180x180.png"
        rel="icon"
        sizes="180x180"
        type="image/png"
      />
      <link
        href="/icons/favicon-32x32.png"
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href="/icons/favicon-16x16.png"
        rel="icon"
        sizes="16x16"
        type="image/png"
      />
      <script type="application/ld+json">
        {JSON.stringify(
          schemaGenerator({
            location,
            canonical,
            siteUrl,
            siteTitle,
            title,
            article,
          }),
        )}
      </script>
    </>
  );
};

export const SEOFragment = graphql`
  fragment SEO on SiteSiteMetadata {
    siteTitle
    siteTitleShort
    siteUrl
    themeColor
    social {
      twitter
      fbAppId
    }
  }
`;

export const SEO = (props: SEOProps) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            ...SEO
          }
        }
      }
    `}
    render={(data) => (
      <Location>
        {({ location }) => (
          <Head {...data.site.siteMetadata} {...props} location={location} />
        )}
      </Location>
    )}
  />
);
