import React, { FC } from 'react';

import { DesktopNav } from './desktop/DesktopNav';
import { MobileNav } from './mobile/MobileNav';

export interface NavigationProps {
  simpleNav?: boolean;
  onPrimaryCtaClick?: () => void;
}

export const Navigation: FC<NavigationProps> = (props) => (
  <>
    <MobileNav {...props} />
    <DesktopNav {...props} />
  </>
);
