import {
  Circle,
  RetirableLogoWithText,
} from 'component-library/components/icons';
import { Icon, IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { NavigationProps } from '../Navigation';
import { baseNavWrapperStyles } from '../shared';
import { DropdownMenu } from './DropdownMenu';

export const Wrapper = styled(ShowOn)`
  ${baseNavWrapperStyles}
  height: 65px;
`;

export const Header = styled(Flex)`
  padding: 12px 20px;
  height: 100%;
`;

export const MobileNav: FC<NavigationProps> = ({ simpleNav, onPrimaryCtaClick }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Wrapper screens={[Screen.Sm, Screen.Md]}>
      <Header alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <LinkWrapper to="/">
            <RetirableLogoWithText />
          </LinkWrapper>
        </Flex>
        {!simpleNav && (
          <Circle onClick={() => setShowMenu((prev) => !prev)}>
            <Icon
              name={showMenu ? IconName.Xmark : IconName.Bars}
              color={colors.white}
            />
          </Circle>
        )}
      </Header>
      {showMenu && <DropdownMenu onPrimaryCtaClick={onPrimaryCtaClick} />}
    </Wrapper>
  );
};
