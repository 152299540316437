// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

interface LocationDetails {
  pathname: string;
}

export interface ArticleDetails {
  title?: string | null;
  author?: string | null;
  slug?: string | null;
  displayedPublishDate?: string | null;
  imageUrl?: string;
}

interface SchemaProps {
  location?: LocationDetails | null;
  canonical?: string | null;
  siteUrl?: string | null;
  title?: string | null;
  siteTitle?: string | null;
  pageTitleFull?: string | null;
  article?: ArticleDetails | null;
}

export default ({
  location,
  canonical,
  siteUrl,
  title,
  siteTitle,
  pageTitleFull,
  article,
}: SchemaProps) => {
  const isSubPage = title && location?.pathname !== '/';

  const schema: any[] = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonical,
      name: title || siteTitle,
      alternateName: pageTitleFull,
    },
  ];

  if (isSubPage) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': canonical,
            name: title,
          },
        },
      ],
    });
  }

  if (article) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'Article',
      '@id': `https://retirable.com/advice/${article.slug}`,
      headline: `${article.title}`,
      author: {
        '@id': `${siteUrl}/#identity`,
        '@type': 'Person',
        name: article.author,
      },
      copyrightHolder: {
        '@id': `${siteUrl}/#identity`,
        name: 'Retirable',
      },
      copyrightYear: article.displayedPublishDate,
      creator: {
        '@id': `${siteUrl}/#creator`,
        '@type': 'Organization',
        name: 'Retirable',
        url: 'https://retirable.com',
        logo: {
          '@type': 'ImageObject',
          url: 'https://retirable.com/short-logo-black.jpg',
        },
      },
      publisher: {
        '@id': `${siteUrl}/#creator`,
        '@type': 'Organization',
        name: 'Retirable',
        url: 'https://retirable.com',
        logo: {
          '@type': 'ImageObject',
          url: 'https://retirable.com/short-logo-black.jpg',
        },
      },
      datePublished: article.displayedPublishDate,
      dateModified: article.displayedPublishDate,
      inLanguage: 'en',
      url: URL,
      name: article.title,
      image: {
        '@type': 'ImageObject',
        url: article?.imageUrl,
      },
      mainEntityOfPage: URL,
    });
  }

  return schema;
};
